<template>
    <panel-alert title="编辑" isShowInfo isShowBottom width="680px" @onCloseClick="onCloseClick"
        @onConfirmClick="onConfirmClick">
        <template v-slot:content>
            <div class="edit-report-alert">
                <div class="select-bar">
                    <div class="select-bar-item" :class="currentSelect == 1 ? 'select-bar-item-select' : ''"
                        @click="onClickSelectBar(1)">数据库导入</div>
                    <div class="select-bar-item" :class="currentSelect == 2 ? 'select-bar-item-select' : ''"
                        @click="onClickSelectBar(2)">手动输入</div>
                </div>
                <div class="edit-report-alert-content">
                    <template v-if="currentSelect == 1">
                        <div class="key-value-item">
                            <must-label width="100px" text="睡眠报告导入"></must-label>
                            <el-autocomplete v-model="reportPhone" ref="autocomplete" placeholder="患者姓名、手机号查询"
                                :popper-append-to-body="false" :fetch-suggestions="querySearch" suffix-icon="el-icon-search"
                                :trigger-on-focus="true" popper-class="search-pop" :debounce="1000"
                                :before-click-outside-close="beforeClickOutsideClose">
                                <template slot-scope="{ item, index }">
                                    <el-popover placement="right" :visible-arrow="false" width="400" trigger="manual"
                                        @show="onPopverShow(item, index)" :ref="'pop_' + index" popper-class="report-popper"
                                        :style="`width: 400px ;zoom:${1 / zoom
                                            };transform:scale(${zoom});transform-origin:0 0`">
                                        <el-collapse v-if="reportList[index]?.reportList">
                                            <el-collapse-item v-for="report, i in reportList[index].reportList"
                                                :key="report.uid">
                                                <template slot="title">
                                                    <div class="report-collapse-title">
                                                        <el-checkbox class="checkbox" v-model="reportListRadio[index][i]"
                                                            @change="e => onReportCheckboxChange(e, index, i)"></el-checkbox>
                                                        <div class="report-collapse-title-item">{{
                                                            reportTime(report.reportTime)
                                                        }}</div>
                                                        <div class="report-collapse-title-item">{{
                                                            sleepTimeScope(report.sleepStartTime, report.sleepEndTime) }}
                                                        </div>
                                                        <div class="report-collapse-title-item">{{
                                                            minutesToHours(report.sleepTime) }}</div>
                                                    </div>
                                                </template>
                                                <div @click="onReportCheckboxChange(null, index, i)" style="height: 100%;">
                                                    <div class="report-collapse-row">
                                                        <div class="report-collapse-item">AHI：{{ report.ahi }}次/小时</div>
                                                        <div class="report-collapse-item">ODI3：{{ report.odi }}次/小时</div>
                                                        <div class="report-collapse-item">AI：{{ report.ai }}次/小时</div>
                                                    </div>
                                                    <div class="report-collapse-row">
                                                        <div class="report-collapse-item">HI：{{ report.hi }}次/小时</div>
                                                        <div class="report-collapse-item">最低血氧：{{ report.spo2min }}%</div>
                                                        <div class="report-collapse-item">平均血氧：{{ report.spo2avg }}%</div>
                                                    </div>
                                                </div>
                                            </el-collapse-item>
                                        </el-collapse>

                                        <div class="list-item" slot="reference"
                                            @mouseenter="onListItemMouseEnter(item, index)">
                                            <div class="name">{{ item.truename }}</div>
                                            <span class="phone">{{ item.mobile }}</span>
                                            <i class="iconfont arrow">&#xe68c;</i>
                                        </div>
                                    </el-popover>
                                </template>
                            </el-autocomplete>
                        </div>
                    </template>
                    <div class="key-value-item key-value-item-birthday">
                        <must-label width="100px" isMust text="日期"></must-label>
                        <el-date-picker :append-to-body="false" v-model="formData.birthday" type="date" placeholder="选择日期"
                            :picker-options="birthdayPickOption" :disabled="currentSelect == 1">
                        </el-date-picker>
                    </div>
                    <div class="key-value-item key-value-item-times">
                        <must-label width="100px" isMust text="AHI"></must-label>
                        <el-input placeholder="请输入" v-model="formData.AHI" :disabled="currentSelect == 1">
                            <p slot="suffix" class="key-value-item-unit">次/小时</p>
                        </el-input>
                    </div>
                    <div class="key-value-item key-value-item-times">
                        <must-label width="100px" isMust text="ODI3"></must-label>
                        <el-input placeholder="请输入" v-model="formData.ODI3" :disabled="currentSelect == 1">
                            <p slot="suffix" class="key-value-item-unit">次/小时</p>
                        </el-input>
                    </div>
                    <div class="key-value-item key-value-item-times">
                        <must-label width="100px" text="AI"></must-label>
                        <el-input placeholder="请输入" v-model="formData.AI" :disabled="currentSelect == 1">
                            <p slot="suffix" class="key-value-item-unit">次/小时</p>
                        </el-input>
                    </div>
                    <div class="key-value-item key-value-item-times">
                        <must-label width="100px" text="HI"></must-label>
                        <el-input placeholder="请输入" v-model="formData.HI" :disabled="currentSelect == 1">
                            <p slot="suffix" class="key-value-item-unit">次/小时</p>
                        </el-input>
                    </div>
                    <div class="key-value-item">
                        <must-label width="100px" isMust text="最低血氧"></must-label>
                        <el-input placeholder="请输入" v-model="formData.spo2min" :disabled="currentSelect == 1">
                            <p slot="suffix" class="key-value-item-unit">%</p>
                        </el-input>
                    </div>
                    <div class="key-value-item">
                        <must-label width="100px" isMust text="平均血氧"></must-label>
                        <el-input placeholder="请输入" v-model="formData.spo2avg" :disabled="currentSelect == 1">
                            <p slot="suffix" class="key-value-item-unit">%</p>
                        </el-input>
                    </div>
                    <template v-if="currentSelect == 1">
                        <div class="key-value-item" v-if="!isEmpty(reportData?.reportId) || !isEmpty(data?.reportId)">
                            <must-label width="100px" text="睡眠报告"></must-label>
                            <a href="#" class="link" @click="onClickReportLink">查看详情</a>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </panel-alert>
</template>
  
<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import MustLabel from "@c/common/alert/panel-alert/components/must-label.vue";
import { DateTool } from "@js/date-tool.js";
import {
    Input,
    DatePicker,
    Popover,
    Collapse,
    CollapseItem,
    Checkbox
} from "element-ui";
import BaselineAutocomplete from './baseline-autocomplete.vue'

export default {
    components: {
        PanelAlert,
        MustLabel,
        //element
        elInput: Input,
        elDatePicker: DatePicker,
        ElAutocomplete: BaselineAutocomplete,
        ElPopover: Popover,
        ElCollapse: Collapse,
        ElCollapseItem: CollapseItem,
        ElCheckbox: Checkbox
    },

    props: {
        data: Object,
    },

    data: function () {
        return {
            birthdayPickOption: {
                disabledDate: (time) => {
                    const MIN_BIRTHDAY = new Date("01/01/1900");
                    const MAX_BIRTHDAY = new Date("12/31/2100");
                    return time.getTime() < MIN_BIRTHDAY || time.getTime() > MAX_BIRTHDAY;
                },
            },
            reportFormData: {
                birthday: null,
                AHI: null,
                ODI3: null,
                AI: null,
                HI: null,
                spo2min: null,
                spo2avg: null,
            },
            manualFormData: {
                birthday: null,
                AHI: null,
                ODI3: null,
                AI: null,
                HI: null,
                spo2min: null,
                spo2avg: null,
            },
            reportData: null,
            reportList: [],
            reportPhone: '',
            reportName: '',
            reportListRadio: [],
            // zoom: 1,
            currentIndex: -1,
            currentSelect: 1,
            mouseX: 0,
            mouseY: 0,
        };
    },

    computed: {
        formData: {
            get() {
                if (this.currentSelect == 1) {
                    return this.reportFormData
                } else {
                    return this.manualFormData
                }
            },
            set(newVal) {
                console.log(newVal);
            }
        },
        zoom() {
            return this.$store.state.zoom
        },
    },

    watch: {
        reportData: {
            handler(newVal) {
                const birthdayStr = newVal.reportTime;
                this.reportFormData.birthday = birthdayStr ? new Date(birthdayStr) : null;
                this.reportFormData.AHI = newVal.ahi;
                this.reportFormData.ODI3 = newVal.odi;
                this.reportFormData.AI = newVal.ai;
                this.reportFormData.HI = newVal.hi;
                this.reportFormData.spo2min = newVal.spo2min;
                this.reportFormData.spo2avg = newVal.spo2avg;
            }
        },
        reportList: {
            handler(newVal) {
                if (newVal === undefined) {
                    return
                }
                this.reportListRadio.splice(0, this.reportListRadio.length)
                newVal.forEach((el, i) => {
                    this.reportListRadio.splice(i, 1, el?.reportList?.map(el1 => {
                        if (el1.reportId === this.reportData?.reportId) {
                            return true
                        } else {
                            return false
                        }
                    }) ?? [])
                })
            }
        },
        activated: {
            handler(newVal) {
                if (newVal) {

                }
            },
            immediate: true,
        },
        currentSelect: {
            handler(newVal) {
                if (newVal == 1) {
                    this.$nextTick(() => {
                        this.$watch(
                            () => this.$refs.autocomplete?.activated, // 要监视的属性或表达式
                            (newValue, oldValue) => {
                                // 在属性变化时执行的回调函数
                                this.$refs['pop_' + this.currentIndex]?.doClose();
                            }
                        )
                    })
                }
            },
            immediate: true,
        }
    },

    created() {
        if (this.data) {
            this.assignment();
        }
    },

    mounted() {
        const app = document.getElementById("app");
        document.addEventListener('mousedown', this.handleMouseMove);
    },
    destroyed() {
        document.removeEventListener('mousedown', this.handleMouseMove);
    },

    methods: {
        async querySearch(queryString, cb) {
            try {
                const data = await this.$api.getInspectionSleepUser({ keywords: queryString })
                this.reportList = data
                cb(this.reportList)
            } catch (e) {
                cb([]);
            }
        },

        async getSleepReport(user) {
            if (user.reportList) {
                if (user.reportList.length != 0) {
                    this.$refs['pop_' + this.currentIndex].doShow();
                }
                return
            }
            try {
                const data = await this.$api.getInspectionSleepReport({ uid: user.uid })
                const findIndex = this.reportList.findIndex(el => el.uid == user.uid)
                if (findIndex !== -1) {
                    this.reportList.splice(findIndex, 1, {
                        ...this.reportList[findIndex],
                        reportList: data.reportList
                    })
                }
                if (data.reportList.length != 0) {
                    this.$refs['pop_' + this.currentIndex].doShow();
                }
            } catch (e) {
            }
        },

        onCloseClick: function () {
            this.$emit("onCloseClick");
        },

        onConfirmClick: function () {
            try {
                this.validateBirthday();
                this.validateNumber("AHI", this.formData.AHI);
                this.validateNumber("ODI3", this.formData.ODI3);
                // this.validateNumber("AI", this.formData.AI);
                // this.validateNumber("HI", this.formData.HI);
                this.validateNumber("最低血氧", this.formData.spo2min);
                this.validateNumber("平均血氧", this.formData.spo2avg);
                this.addOrEditReport();
            } catch (error) {
                this.$toast.showRed(error);
            }
        },

        onClickSelectBar(index) {

            if (index == this.currentSelect) {
                return
            }
            this.currentSelect = index
        },
        //method

        async addOrEditReport() {
            let params = {
                id: this.data.id,
                userId: this.data.userId,
                hospitalId: this.data.hospitalId,
                reportTime: DateTool.dateToStr(this.formData.birthday),
                ahi: this.formData.AHI,
                odi: this.formData.ODI3,
                ai: this.formData.AI,
                hi: this.formData.HI,
                spo2min: this.formData.spo2min,
                spo2avg: this.formData.spo2avg,
            };
            if (this.currentSelect == 1) {
                params.reportId = this.reportData ? this.reportData.reportId : this.data.reportId
                params.userName = this.reportName;
                params.userPhone = this.reportPhone;
            } else {
                params.reportId = null
                params.userName = null;
                params.userPhone = null;
            }
            this.$emit("onSucceed", params);
        },

        isEmpty(value) {
            return value == undefined || value === "" || value === null;
        },

        validateBirthday() {
            if (this.isEmpty(this.formData.birthday)) {
                throw `日期不能为空`;
            }
            var currentDate = new Date();
            var currentDayStr = DateTool.dateToStr(currentDate, "YYYY-MM-DD");
            var birthDayStr = DateTool.dateToStr(this.formData.birthday, "YYYY-MM-DD");
            if (birthDayStr > currentDayStr) {
                throw `日期不能晚于今天`;
            }
        },

        validateEmpty(type, value) {
            if (this.isEmpty(value)) {
                throw `${type}为必填，请检查！`;
            }
        },

        validateNumber(type, value) {
            if (value == '' || value == null) {
                throw `请输入${type}`;
            }
            if (isNaN(value) || value < 0) {
                throw `${type}必须为正数`;
            }
            if (value.indexOf(".") != -1 && value.split(".")[1].length > 2) {
                throw `${type}最多两位小数`;
            }
            if (value > 300) {
                throw `${type}不能超过300`;
            }
        },

        assignment() {
            //birthday
            if (this.data.reportId && this.data.reportId != '') {
                this.currentSelect = 1
            } else {
                this.currentSelect = 2
            }
            const birthdayStr = this.data.reportTime;
            this.formData.birthday = birthdayStr ? new Date(birthdayStr) : null;
            this.formData.AHI = this.data.ahi;
            this.formData.ODI3 = this.data.odi;
            this.formData.AI = this.data.ai;
            this.formData.HI = this.data.hi;
            this.formData.spo2min = this.data.spo2min;
            this.formData.spo2avg = this.data.spo2avg;
            this.reportPhone = (this.isEmpty(this.data?.userName) ? '' : `${this.data?.userName} `) + (this.data?.userPhone ?? '')
        },
        minutesToHours(min) {
            if (this.isEmpty(min)) {
                return ''
            }
            return DateTool.minutesToHours(min)
        },
        reportTime(val) {
            if (this.isEmpty(val)) {
                return ''
            }
            return DateTool.timeStrToStr(val, 'YYYY-MM-DD')
        },
        sleepTimeScope(sleepStartTime, sleepEndTime) {
            if (this.isEmpty(sleepStartTime) || this.isEmpty(sleepEndTime)) {
                return ''
            }
            const startTime = new Date(sleepStartTime);
            const endTime = new Date(sleepEndTime);
            // 获取转换后的时间字符串
            const formattedStartTime = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const formattedEndTime = endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            // 组合成目标格式
            const sleepTimeRange = `${formattedStartTime}-${formattedEndTime}`;
            return sleepTimeRange
        },
        onReportCheckboxChange(e, index, jIndex) {
            this.$refs.autocomplete.close()
            this.$refs['pop_' + index].doClose();
            this.currentIndex = -1
            this.reportData = this.reportList[index]?.reportList[jIndex]
            this.reportPhone = this.reportList[index]?.mobile ?? ''
            this.reportName = this.reportList[index]?.truename ?? ''
            for (let i = 0; i < this.reportListRadio.length; i++) {
                for (let j = 0; j < this.reportListRadio[i].length; j++) {
                    if (i !== index || j !== jIndex) {
                        this.reportListRadio[i].splice(j, 1, false)
                    }
                }
            }
        },
        onPopverShow(user, index) {
            // this.$nextTick(() => {
            //     const component = this.$refs['pop_' + index];
            //     const popper = component.$refs.popper
            //     const currentTop = parseFloat(popper.style.top);
            //     const currentLeft = parseFloat(popper.style.left);
            //     const newTop = currentTop * (+this.zoom);
            //     const newLeft = currentLeft * (+this.zoom);
            //     popper.style.top = `${newTop}px`;
            //     popper.style.left = `${newLeft}px`;
            // });
        },

        onListItemMouseEnter(user, index) {
            if (this.currentIndex != -1) {
                this.$refs['pop_' + this.currentIndex]?.doClose();
            }

            this.currentIndex = index;
            this.getSleepReport(user)
        },

        onListItemMouseLeave(user, index) {
            this.$refs['pop_' + index]?.doClose();
        },
        //点击报告链接
        onClickReportLink() {
            const reportID = this.reportData?.reportId ? this.reportData?.reportId : this.data.reportId;
            const reportURL = `${process.env.VUE_APP_SLEEP_REPORT_BASE_URL}report?reportURL=${reportID}`;
            window.open(reportURL, "_blank");
        },

        handleMouseMove(event) {
            this.mouseX = event.clientX;
            this.mouseY = event.clientY;
        },
        //点击autocomplete外部
        beforeClickOutsideClose() {
            if (this.$refs['pop_' + this.currentIndex] === void 0) {
                return true
            }
            const pop = this.$refs['pop_' + this.currentIndex]?.$refs.popper
            const targetComponentRect = pop.getBoundingClientRect();
            const isInsideTargetComponent =
                this.mouseX >= targetComponentRect.left &&
                this.mouseX <= targetComponentRect.left + targetComponentRect.width &&
                this.mouseY >= targetComponentRect.top &&
                this.mouseY <= targetComponentRect.top + targetComponentRect.height;
            return !isInsideTargetComponent
        },
    },
};
</script>
  
<style lang="scss" scoped>
@import "@css/theme/right-arrow.scss";

/deep/.panel-alert-content {
    padding: 0 0 10px;
}

/deep/.panel-alert-bottom {
    border: none;
}

.edit-report-alert {
    .select-bar {
        display: flex;
        align-items: center;
        height: 40px;
        border-bottom: 1px solid #ddd;

        &-item {
            margin: 0 10px;
            padding: 0 10px;
            font-size: 14px;
            color: #333;
            height: 40px;
            line-height: 40px;
            box-sizing: border-box;
        }

        &-item-select {
            color: #2779FF;
            border-bottom: 1px solid #2779FF;
        }
    }

    &-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding-right: 40px;
        padding-left: 24px;
    }

    &-line {
        background-color: rgba($color: #000000, $alpha: 0.09);
        width: 100%;
        height: 1px;
        margin-top: 9px;
    }
}

.key-value-item {
    display: flex;
    align-items: center;
    margin-top: 9px;

    &-unit {
        color: rgba(0, 0, 0, 0.25);
        font-size: 14px;
        height: 100%;
        margin-right: 5px;
        display: flex;
        align-items: center;
    }

    /deep/.el-input__inner {
        font-size: 14px;
        height: 32px;
        width: 182px;
    }

    /deep/.el-input__icon {
        line-height: 32px;
    }

    &-birthday {
        /deep/.el-date-editor.el-input {
            width: 182px;
        }
    }

    &-times {
        /deep/.el-input__inner {
            padding-right: 60px;

        }
    }


    &-value {
        color: rgba($color: #000000, $alpha: 0.85);
        font-size: 14px;
        width: 50px;
    }
}

.search-pop {
    width: 250px !important;

    li {
        line-height: normal;
        padding: 7px;

        .hidhlighted .addr {
            color: #ddd;
        }
    }
}

.list-item {
    color: #333;
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;

    .name {
        height: 22px;
        line-height: 22px;
        max-width: 70px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .phone {
        margin-left: 10px;
        height: 22px;
        line-height: 22px;
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .arrow {
        line-height: 22px;
        margin-left: auto;
        display: block;
        height: 22px;
        width: 10px;
    }

    &:hover {
        color: #2779FF;
    }
}

.report-collapse-title {
    display: flex;
    align-items: center;
    width: 375px;

    .checkbox {
        margin-left: 10px;
        margin-right: 15px;
    }

    /deep/ .el-checkbox__inner {
        border-radius: 50%;
    }


    &-item {
        max-width: 180px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #888888;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 10px;
    }
}


.report-collapse-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

}

.report-collapse-item {
    width: 33%;
    height: 24px;
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    line-height: 24px;
    text-align: center;
}

.report-popper {}

.link {
    display: flex;
    align-items: center;
    margin-left: 10px;
    height: 35px;
    font-size: 14px;
    font-weight: 500;
    color: #5889EB;
    line-height: 35px;
    float: right;
    cursor: pointer;
    text-decoration: none;
}
</style>
  
<style></style>